<template>
  <div class="navigation">
    <div class="hidden lg:flex justify-center mt-9 mx-auto">
      <router-link to="/publipostages">
        <img
          src="@/assets/img/logo-blanc.svg"
          alt="Logo du publipostage"
          class="h-10 w-auto"
        />
      </router-link>
    </div>
    <nav class="mt-24 lg:mt-20">
      <NavItem
        logo="publipostage"
        link="/publipostages"
        menu-name="Publipostages"
      >
        Publipostage
      </NavItem>
      <NavItem
        v-if="isAdmin"
        logo="user"
        link="/utilisateurs/technitoit"
        menu-name="Utilisateurs"
      >
        Gestion <br />
        des utilisateurs
      </NavItem>
      <NavItem logo="agence" link="/agences" menu-name="Agences">
        Gestion <br />
        des agences
      </NavItem>
      <!--<NavItem
        v-if="isAdmin"
        logo="blacklist"
        link="/blacklist"
        menu-name="Blacklist"
      >
        Blacklist
      </NavItem>-->
      <NavItem logo="reporting" link="/reporting" menu-name="Reporting">
        Reporting
      </NavItem>
    </nav>
  </div>
</template>

<script>
import NavItem from "@/components/utils/menu/NavItem";
import { isAdmin } from "@/helpers/user";

export default {
  name: "Menu",
  components: {
    NavItem,
  },
  props: {},
  computed: {
    isAdmin() {
      return isAdmin();
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation {
  @apply fixed left-0 top-0 h-screen w-24 lg:w-80 px-4 lg:px-8;
  background: transparent linear-gradient(226deg, #f42554 0%, #e61da1 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}
</style>
