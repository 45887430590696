<template>
  <router-link
    v-if="!noRouter"
    :to="link"
    :class="buildClasses"
    class="title px-4 hover:text-primary font-medium"
  >
    <slot />
  </router-link>
  <span
    v-else
    :class="buildClasses"
    class="title px-4 hover:text-primary font-medium"
    @click="handleClick"
  >
    <slot />
  </span>
</template>

<script>
export default {
  name: "SecondaryNavItem",
  props: {
    link: {
      type: String,
      required: true,
    },
    noRouter: {
      type: Boolean,
      default: false,
    },
    currentValue: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    customClass: "",
  }),
  computed: {
    fullPath() {
      return this.$route.fullPath;
    },
    isActive() {
      return this.fullPath === this.link || this.currentValue === this.link;
    },
    buildClasses() {
      return {
        "text-primary isactif": this.isActive,
        "text-gray-300": !this.isActive,
      };
    },
  },
  methods: {
    handleClick() {
      this.$emit("input", this.link);
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  position: relative;
  cursor: pointer;
}

.title:hover::after,
.isactif::after {
  background-color: #ff3693;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0);
  width: 60px;
}

.isactif {
  color: #ff3693;
}
</style>
