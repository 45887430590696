<template>
  <div>
    <div class="h-16 flex items-center">
      <h1 class="text-base lg:text-xl font-semibold mr-auto">Reporting</h1>
      <slot name="header-buttons"></slot>
    </div>
    <div class="my-11">
      <filter-bar
        v-model="formFilters"
        storage-key="reporting_filters"
        :config="filterConfig"
      />
      <div v-if="filtersAreComplete" class="mt-8">
        <div class="flex -mx-5 h-60">
          <publipostages-percentage :filters="filters" class="w-1/2 mx-5" />
          <turnover-total :filters="filters" class="w-1/2 mx-5" />
        </div>
        <div class="w-full mt-10">
          <amount-by-publipostage :filters="filters" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FilterBar from "@/components/filters/FilterBar";
import { FILTER_TYPES } from "@/components/filters/filterTypes";
import AmountByPublipostage from "@/components/reporting/AmountByPublipostage";
import PublipostagesPercentage from "@/components/reporting/PublipostagesPercentage";
import TurnoverTotal from "@/components/reporting/TurnoverTotal";

export default {
  name: "Reporting",
  components: {
    PublipostagesPercentage,
    FilterBar,
    AmountByPublipostage,
    TurnoverTotal,
  },
  data() {
    return {
      formFilters: {
        agence: {},
        month: {},
        year: {},
      },
      filterConfig: [
        {
          fields: ["agence"],
          type: FILTER_TYPES.AGENCE_TYPE,
          props: {
            widthClass: ["w-60"],
          },
        },
        {
          fields: ["month", "year"],
          type: FILTER_TYPES.MONTH_YEAR_TYPE,
        },
      ],
    };
  },
  computed: {
    filters() {
      return Object.keys(this.formFilters).reduce((acc, current) => {
        acc[current] = this.formFilters[current].value || null;
        return acc;
      }, {});
    },

    filtersAreComplete() {
      let areComplete = true;
      for (const filter in this.filters) {
        if (this.filters[filter] === null) {
          areComplete = false;
          break;
        }
      }
      return areComplete;
    },
  },
};
</script>
