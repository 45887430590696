<template>
  <div class="flex flex-col border border-gray-200 gap-4 rounded-2xl p-5">
    <h2 class="font-medium text-gray-400 mb-4">
      Montant du CA par publipostage
    </h2>
    <line-chart
      v-if="isLoaded"
      :chart-data="chartData"
      :options="options"
      :plugins="plugins"
    />
  </div>
</template>

<script>
import { reportingService } from "@/services";
import LineChart from "@/components/reporting/indicators/LineChart";
import { currencyFormatter } from "@/helpers";

export default {
  name: "AmountByPublipostage",
  components: {
    LineChart,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartData: {
        datasets: [
          {
            borderColor: "#FF3693",
            backgroundColor: "rgba(255, 54, 147, 0.1)",
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                padding: 15,
                maxRotation: 0,
                fontColor: "#a0a0a0",
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                callback: (value, index) => {
                  if (index % 2 === 0) return "";
                  return currencyFormatter.format(value);
                },
                padding: 15,
                fontColor: "#a0a0a0",
              },
              gridLines: {
                drawTicks: false,
              },
            },
          ],
        },
        layout: {
          padding: {
            top: 20,
            left: 20,
          },
        },
        tooltips: {
          backgroundColor: "#fff",
          xPadding: 20,
          yPadding: 10,
          caretSize: 0,
          cornerRadius: 10,
          titleFontColor: "#ff3693",
          titleFontSize: 16,
          titleAlign: "center",
          bodyFontColor: "#a0a0a0",
          bodyAlign: "center",
          shadowOffsetX: 3,
          shadowOffsetY: 3,
          shadowBlur: 10,
          shadowColor: "rgba(0, 0, 0, 0.5)",
          displayColors: false,
          callbacks: {
            title: (tooltipItems) => {
              const item = tooltipItems[0];
              return currencyFormatter.format(item.value);
            },
            label: (tooltipItem) => {
              const nbAdresses = this.nbAdresses[tooltipItem.index];
              return nbAdresses + " adresse" + (nbAdresses > 1 ? "s" : "");
            },
          },
        },
      },
      isLoaded: false,
      nbAdresses: null,
      plugins: [
        {
          afterDraw: (chart) => {
            const ctx = chart.chart.ctx;
            const yAxisWidth = chart.scales["y-axis-0"].width;
            const textWidth = 40;
            const x = yAxisWidth - textWidth;
            ctx.save();
            ctx.font = "bold 14px Arial";
            ctx.fillStyle = "black";
            ctx.textAlign = "left";
            ctx.fillText("Montant", x, 10);
            ctx.fillText("du CA", x + 5, 25);
            ctx.restore();
          },
        },
      ],
    };
  },
  watch: {
    filters() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      const chartData = await reportingService.getAmountByPublipostageData(
        this.filters
      );
      const chartDataCopy = { ...this.chartData };
      chartDataCopy.labels = chartData.labels;
      chartDataCopy.datasets[0].data = chartData.data;
      this.chartData = chartDataCopy;
      this.nbAdresses = chartData.nbAdresses;
      this.isLoaded = true;
    },
  },
};
</script>

<style scoped></style>
