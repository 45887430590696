<template>
  <div>
    <Error :title="errors.commonError" />
    <form class="flex flex-wrap -mx-3">
      <div class="w-1/2 px-3 mb-8">
        <Select
          :data="marques"
          :value="marque.value"
          @valueSelect="onValueSelect"
        >
          <template v-slot:SelectLabel> Marques </template>
        </Select>
        <Error :title="errors.marque" />
      </div>
      <div class="w-1/2 px-3 mb-8">
        <label for="nom" class="text-md px-1 mb-2">Nom de l'agence</label>
        <input
          id="nom"
          v-model="formNom"
          name="nom"
          type="text"
          class="form-input"
          required
        />
        <Error :title="errors.nom" />
      </div>
      <div class="w-1/2 px-3 mb-8">
        <label for="responsableNom" class="text-md px-1 mb-2"
          >Nom du référent</label
        >
        <input
          id="responsableNom"
          v-model="formResponsableNom"
          name="responsableNom"
          type="text"
          class="form-input"
          required
        />
        <Error :title="errors.responsableNom" />
      </div>
      <div class="w-1/2 px-3 mb-8">
        <label for="responsableTelephone" class="text-md px-1 mb-2"
          >Numéros de téléphone du réferent</label
        >
        <input
          id="responsableTelephone"
          v-model="formResponsableTelephone"
          name="responsableTelephone"
          type="text"
          class="form-input"
          required
        />
        <Error :title="errors.responsableTelephone" />
      </div>
      <div class="w-full px-3 mb-8">
        <label for="adresse" class="text-md px-1 mb-2">Adresse</label>
        <input
          id="adresse"
          v-model="formAdresse"
          name="adresse"
          type="text"
          class="form-input"
          required
        />
        <Error :title="errors.adresse" />
      </div>
      <div class="w-1/2 px-3 mb-8">
        <label for="codePostal" class="text-md px-1 mb-2">Code postal</label>
        <input
          id="codePostal"
          v-model="formCodePostal"
          name="codePostal"
          type="text"
          class="form-input"
          required
        />
        <Error :title="errors.codePostal" />
      </div>
      <div class="w-1/2 px-3 mb-8">
        <label for="ville" class="text-md px-1 mb-2">Ville</label>
        <input
          id="ville"
          v-model="formVille"
          name="ville"
          type="text"
          class="form-input"
          required
        />
        <Error :title="errors.ville" />
      </div>
      <div class="w-full px-3 mb-8">
        <label for="nbResultatsMax" class="text-md px-1 mb-2"
          >Nombre de résultat à rechercher</label
        >
        <input
          id="nbResultatsMax"
          v-model="formNbResultatsMax"
          name="nbResultatsMax"
          type="number"
          class="form-input"
          required
        />
        <Error :title="errors.nbResultatsMax" />
      </div>
      <div class="w-full px-4 mb-8 flex items-center">
        <input
          id="defaultParticipate"
          v-model="formDefaultParticipate"
          name="defaultParticipate"
          type="checkbox"
          class="flex-grow-0"
        />
        <label for="defaultParticipate" class="text-md mx-3"
          >Participe par défaut à tous les publipostages</label
        >
        <Error :title="errors.defaultParticipate" />
      </div>
    </form>
  </div>
</template>

<script>
import AgenceModel from "@/model/Agence";
import Error from "@/components/forms/utils/Error";
import Select from "@/components/forms/utils/Select";
import { catchErrors } from "@/helpers";

export default {
  name: "AgenceForm",
  components: {
    Select,
    Error,
  },
  props: {
    agence: {
      type: Object,
      default: function () {
        return {};
      },
    },
    agenceUuid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      marqueUuid: "",
      formNom: "",
      formAdresse: "",
      formCodePostal: "",
      formVille: "",
      formResponsableNom: "",
      formResponsableTelephone: "",
      formNbResultatsMax: 100,
      marque: { key: "technitoit", value: "Technitoit" },
      marques: [
        { key: "technitoit", value: "Technitoit" },
        { key: "europe_energie", value: "Europe Energie" },
      ],
      formDefaultParticipate: true,
      errors: {
        commonError: null,
        marque: null,
        nom: null,
        adresse: null,
        codePostal: null,
        ville: null,
        responsableNom: null,
        responsableTelephone: null,
        nbResultatsMax: null,
        defaultParticipate: null,
      },
    };
  },
  computed: {
    getAgenceModel() {
      const marque = this.$store.state.marques.marques.filter((marque) => {
        return marque.code === this.marque.key;
      });

      if (Object.entries(marque).length === 0) {
        return;
      }

      return new AgenceModel(
        this.formNom,
        this.formResponsableTelephone,
        this.formResponsableNom,
        this.formAdresse,
        this.formCodePostal,
        this.formVille,
        this.formNbResultatsMax,
        Array.from(marque)[0].uuid,
        this.formDefaultParticipate
      );
    },
  },
  mounted() {
    if (Object.entries(this.agence).length !== 0) {
      this.formNom = this.agence.nom;
      this.formAdresse = this.agence.adresse;
      this.formCodePostal = this.agence.codePostal;
      this.formVille = this.agence.ville;
      this.formResponsableNom = this.agence.responsableNom;
      this.formResponsableTelephone = this.agence.responsableTelephone;
      this.formNbResultatsMax = this.agence.nbResultatsMax;
      this.formDefaultParticipate = this.agence.defaultParticipate;

      switch (this.agence.marque.code) {
        case "technitoit":
          this.marque = { key: "technitoit", value: "Technitoit" };
          break;
        case "europe_energie":
          this.marque = { key: "europe_energie", value: "Europe Energie" };
          break;
      }
    }
  },
  methods: {
    handleCreateSubmit() {
      this.resetErrorsMessage();
      const { dispatch } = this.$store;
      const agence = this.getAgenceModel;

      if (agence) {
        dispatch("agences/createAgence", {
          agence: agence,
          page: this.marque.key,
        })
          .then(() => {
            this.$emit("closeModal");
          })
          .catch((errors) => {
            catchErrors(errors, this.errors);
            dispatch("loading/stopLoad", "", { root: true });
          });
      }
    },
    handleEditSubmit(agenceFilter) {
      this.resetErrorsMessage();
      const { dispatch } = this.$store;
      const agence = this.getAgenceModel;
      const agenceUuid = this.agenceUuid;

      if (agenceUuid && agence) {
        dispatch("agences/updateAgence", {
          agence,
          agenceUuid,
          agenceFilter,
        })
          .then(() => {
            this.$emit("closeModal");
          })
          .catch((errors) => {
            catchErrors(errors, this.errors);
            dispatch("loading/stopLoad", "", { root: true });
          });
      }
    },
    onValueSelect(value) {
      this.marque = value;
    },
    resetErrorsMessage() {
      Object.keys(this.errors).forEach((k) => (this.errors[k] = null));
    },
  },
};
</script>
