<template>
  <div id="request-page">
    <div class="flex items-center justify-center fixed top-0 left-0">
      <router-link to="/login">
        <img
          src="@/assets/img/logo-noir.svg"
          alt="Logo du publipostage"
          class="py-4 w-64"
        />
      </router-link>
    </div>
    <div
      class="flex flex-wrap justify-center items-center min-h-screen min-w-full"
    >
      <div class="w-11/12 md:w-6/12 xl:4/12 px-10 rounded-3xl shadow-xl p-4">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-3 pt-3">
            <router-link to="/login">
              <img
                src="@/assets/ico/retour-arrow.svg"
                alt="Fleche de retour à l'accueil"
                class="w-8"
              />
            </router-link>
          </div>
          <div class="col-span-6 text-center mb-10">
            <h1 class="text-3xl text-gray-900">Mot de passe oublié ?</h1>
          </div>
        </div>
        <form @submit.prevent="handleSubmit">
          <div class="flex-mx-3">
            <div class="w-full px-3 mb-8">
              <label for="email" class="text-md px-1 mb-2">Email</label>
              <input
                id="email"
                v-model="email"
                name="email"
                type="email"
                class="
                  w-full
                  pl-6
                  pr-3
                  py-2
                  rounded-full
                  border border-gray-200
                  outline-none
                  focus:border-primary
                  caret-primary
                "
                required
              />
            </div>
          </div>
          <div class="flex -mx-3 mb-8">
            <div class="w-full text-center px-4">
              <p v-if="alert.type === 'alert-success'" class="text-green-400">
                {{ alert.message }}
              </p>
              <p v-if="alert.type === 'alert-danger'" class="text-red-400">
                {{ alert.message }}
              </p>
            </div>
          </div>
          <div class="flex -mx-3">
            <div class="w-full px-3 mb-5">
              <button
                id="submitButton"
                class="block w-full text-white rounded-full px-3 py-3"
              >
                Réinitialiser mon mot de passe
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RequestPassword",
  data() {
    return {
      email: "",
    };
  },
  computed: {
    loggingIn() {
      return this.$store.state.authentication.status.loggingIn;
    },
    alert() {
      return this.$store.state.alert;
    },
  },
  methods: {
    handleSubmit() {
      this.submitted = true;
      const { email } = this;
      const { dispatch } = this.$store;
      if (email) {
        dispatch("authentication/requestReset", { email });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#submitButton {
  background: transparent linear-gradient(268deg, #f5264c 0%, #e31bb1 100%) 0%
    0% no-repeat padding-box;

  &:hover {
    background: transparent linear-gradient(268deg, #e31bb1 0%, #f5264c 100%) 0%
      0% no-repeat padding-box;
  }
}

button:focus {
  outline: none;
}

input {
  caret-color: #ff3693;
}
</style>
