var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Error',{attrs:{"title":_vm.errors.commonError}}),_c('form',{staticClass:"flex flex-wrap"},[_c('div',{staticClass:"w-full mb-6"},[_c('label',{staticClass:"px-1 mb-2",attrs:{"for":"nom"}},[_vm._v("Nom du publipostage")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.publipostage.nom),expression:"publipostage.nom"}],staticClass:"form-input",attrs:{"id":"nom","name":"nom","type":"text","required":""},domProps:{"value":(_vm.publipostage.nom)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.publipostage, "nom", $event.target.value)}}}),_c('Error',{attrs:{"title":_vm.errors.nom}})],1),_c('div',{staticClass:"w-full mb-6"},[_c('Select',{attrs:{"data":_vm.searchMarquesOptions,"value":_vm.publipostage.marque.value},on:{"valueSelect":_vm.onValueSelect},scopedSlots:_vm._u([{key:"SelectLabel",fn:function(){return [_vm._v(" Marques ")]},proxy:true}])}),_c('Error',{attrs:{"title":_vm.errors.marque}})],1),_c('div',{staticClass:"w-full mb-6"},[_c('label',{staticClass:"px-1 mb-2",attrs:{"for":"dateMiseEnLigne"}},[_vm._v("Date de mise en ligne")]),_c('datetime',{staticClass:"border border-gray-200 rounded-full pl-6 pr-3 py-2 w-full",attrs:{"id":"dateMiseEnLigne","type":"datetime","format":{
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
        },"phrases":{ ok: 'Ok', cancel: 'Annuler' }},model:{value:(_vm.publipostage.dateMiseEnLigne),callback:function ($$v) {_vm.$set(_vm.publipostage, "dateMiseEnLigne", $$v)},expression:"publipostage.dateMiseEnLigne"}}),_c('Error',{attrs:{"title":_vm.errors.dateMiseEnLigne}})],1),_c('div',{staticClass:"w-full mb-6"},[_c('label',{staticClass:"px-1 mb-2",attrs:{"for":"dateLimite"}},[_vm._v("Date limite")]),_c('datetime',{staticClass:"border border-gray-200 rounded-full pl-6 pr-3 py-2 w-full",attrs:{"id":"dateLimite","type":"datetime","format":{
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
        },"phrases":{ ok: 'Ok', cancel: 'Annuler' }},model:{value:(_vm.publipostage.dateLimite),callback:function ($$v) {_vm.$set(_vm.publipostage, "dateLimite", $$v)},expression:"publipostage.dateLimite"}}),_c('Error',{attrs:{"title":_vm.errors.dateLimite}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }