<template>
  <div v-click-outside="closeProfil" class="relative">
    <button
      id="user-menu"
      class="
        bg-white
        flex
        items-center
        text-sm
        rounded-t-xl
        border
        px-4
        shadow-lg
        h-12
        lg:h-15
      "
      :class="isOn ? '' : 'rounded-b-xl'"
      aria-haspopup="true"
      @click="isOn = !isOn"
    >
      <span class="sr-only">Open user menu</span>
      <div
        class="
          rounded-full
          h-7
          w-7
          lg:h-8
          lg:w-8
          flex
          items-center
          justify-center
          text-white
        "
        :class="'bg-' + user.color"
      >
        {{ user.initials }}
      </div>
      <p class="ml-4 text-gray-700">
        {{ user.name }}
      </p>
      <transition
        enter-active-class="transition ease-out duration-100"
        enter-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <span class="ml-6"
          ><font-awesome-icon
            icon="chevron-right"
            :class="[isOn ? 'go' : 'aa']"
        /></span>
      </transition>
    </button>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-show="isOn"
        class="
          origin-top-right
          absolute
          right-0
          w-48
          rounded-b-xl
          py-1
          bg-white
          border
          shadow-lg
          w-full
        "
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="user-menu"
      >
        <p
          class="
            cursor-pointer
            block
            px-4
            py-2
            text-sm text-gray-700
            hover:bg-gray-100
          "
          role="menuitem"
          @click="logout"
        >
          Déconnexion
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
export default {
  name: "UserProfil",
  directives: {
    ClickOutside,
  },
  props: {},
  data: () => ({
    isOn: false,
    user: [],
  }),
  created() {
    this.user = this.$store.state.users.currentUser;
  },
  methods: {
    logout() {
      this.$store.dispatch("authentication/logout");
    },
    closeProfil() {
      this.isOn = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.aa {
  transition: all 0.5s;
}
.go {
  transform: rotate(90deg);
  transition: all 0.5s;
}
</style>
