<template>
  <div class="relative border border-gray-200 rounded-2xl w-full">
    <div
      class="absolute top-0 left-0 rounded-l-lg h-full flex-shrink-0 w-2"
      :class="getCardsClass(participe.publipostage.marque)"
    />
    <div class="flex flex-col p-3 pl-5 h-full">
      <div class="flex justify-between items-center">
        <div class="flex flex-wrap mb-3 self-start">
          <span class="w-full font-bold flex-grow">{{
            participe.publipostage.nom
          }}</span>
          <span class="w-full text-sm flex-grow">{{
            participe.agence.nom
          }}</span>
        </div>
        <div class="flex flex-wrap min-w-max">
          <span
            v-if="
              participe.isDone && participe.publipostage.status === 'ENVOYE'
            "
            class="
              px-4
              py-2
              rounded-full
              flex-none
              text-sm
              w-full
              text-center
              mt-1
            "
            style="background: #e1ebff; color: #267eb1"
            >Envoyé</span
          >
          <span
            v-if="participe.isDone"
            class="
              px-4
              py-2
              rounded-full
              flex-none
              text-sm
              w-full
              text-center
              mt-1
            "
            style="background: #ebfbef; color: #7bd96e"
            >Validé</span
          >
          <span
            v-if="
              !participe.isDone && participe.publipostage.status !== 'ACTIF'
            "
            class="
              px-4
              py-2
              rounded-full
              flex-none
              text-sm
              bg-red-100
              text-red-500
              w-full
              text-center
              mt-1
            "
            >Délai dépassé</span
          >
          <span
            v-if="
              !participe.isDone && participe.publipostage.status === 'ACTIF'
            "
            class="
              px-4
              py-2
              rounded-full
              flex-none
              text-sm
              w-full
              text-center
              mt-1
            "
            style="background: #feeff6; color: #ff3693"
            >À faire</span
          >
        </div>
      </div>
      <div class="flex items-center justify-between mt-2 mb-3">
        <div class="flex">
          <img src="@/assets/ico/date.svg" alt="icone date" class="w-5 mr-2" />
          <p class="text-sm">
            {{ dateFormat(participe.publipostage.dateLimite) }}
          </p>
        </div>
        <div class="flex">
          <p class="text-sm">
            {{
              nbJoursRestant(
                participe.publipostage.dateLimite,
                participe.publipostage
              )
            }}
          </p>
        </div>
      </div>
      <span
        v-if="participe.isDone && participe.publipostage.status === 'ENVOYE'"
        class="w-full flex flex-wrap justify-center text-sm flex-grow mt-2"
      >
        <button
          class="flex items-end w-full mb-4"
          @click="exportCsv(participe.publipostage.uuid, participe.agence.uuid)"
        >
          <img
            src="@/assets/ico/exporter.svg"
            alt="Exporter"
            class="h-6 w-6 mr-1"
          />
          Télécharger la liste des adresses.
        </button>
        <router-link
          :to="{
            name: 'DeclareVente',
            params: {
              publipostageUuid: participe.publipostage.uuid,
              agenceUuid: participe.agence.uuid,
            },
          }"
        >
          <button class="bg-indigo-900 text-white rounded-full py-2 px-6">
            Déclarer les ventes
          </button>
        </router-link>
      </span>
      <div
        v-if="participe.isDone && participe.publipostage.status === 'ACTIF'"
        class="flex justify-center w-full mt-auto"
      >
        <router-link
          :to="{
            name: 'ListAdresse',
            params: {
              publipostageUuid: participe.publipostage.uuid,
              agenceUuid: participe.agence.uuid,
            },
          }"
        >
          <button class="bg-blue-500 text-white rounded-full py-2 px-6">
            Modifier
          </button>
        </router-link>
      </div>
      <div
        v-if="!participe.isDone && participe.publipostage.status === 'ACTIF'"
        class="flex justify-center mt-auto"
      >
        <router-link
          :to="{
            name: 'GeneratePublipostage',
            params: {
              publipostageUuid: participe.publipostage.uuid,
              agenceUuid: participe.agence.uuid,
            },
          }"
        >
          <button class="bg-indigo-900 text-white rounded-full py-2 px-6">
            Générer
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ParticipeCards",
  components: {},
  props: {
    participe: {
      type: Object,
      required: true,
    },
    participeFilter: {
      type: Object,
      required: true,
    },
  },
  methods: {
    dateFormat(date) {
      const dateOptions = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const timeOptions = {
        hour: "2-digit",
        minute: "2-digit",
      };

      const objDate = new Date(date);

      return (
        objDate.toLocaleDateString("fr-FR", dateOptions) +
        " à " +
        objDate.toLocaleTimeString("fr-FR", timeOptions)
      );
    },
    nbJoursRestant(date2, publipostage) {
      let objetDate1 = new Date();
      let objetDate2 = new Date(date2);
      if (objetDate1 > objetDate2 || publipostage.status !== "ACTIF") {
        return "";
      }

      let diffTemps = objetDate2.getTime() - objetDate1.getTime();
      return (diffTemps / (1000 * 3600 * 24)).toFixed() + "  jour(s) restants";
    },
    getCardsClass(marque) {
      if (marque.code === "technitoit") {
        return "bg-technitoit";
      }

      return "bg-europeenergie";
    },
    exportCsv(publipostageUuid, agenceUuid) {
      const { dispatch } = this.$store;

      dispatch({
        type: "participes/exportPublipostages",
        publipostageUuid: publipostageUuid,
        agenceUuid: agenceUuid,
      });
    },
  },
};
</script>
