<template>
  <div>
    <div class="h-16 flex items-center">
      <h1 class="text-base lg:text-xl font-semibold mr-auto">Publipostage</h1>
      <slot name="header-buttons" />
    </div>
    <div class="my-11">
      <filter-bar
        v-model="participeFilter"
        storage-key="participe_filters"
        :config="filterConfig"
      />
      <div class="flex border border-gray-200 gap-4 my-8 rounded-2xl p-8">
        <div
          class="
            grid grid-cols-1
            md:grid-cols-2
            lg:grid-cols-3
            gap-8
            w-full
            my-5
          "
        >
          <participe-cards-user
            v-for="(participe, index) in participes"
            :key="index"
            :participe="participe"
            :participe-filter="participeFilter"
          />
          <div
            v-show="participes && participes.length === 0"
            class="col-span-full flex justify-center items-center py-5"
          >
            <p
              class="
                px-8
                py-3
                rounded-full
                bg-primary
                text-white
                cursor-pointer
                hover:shadow
              "
              @click="clearFilter"
            >
              Aucun publipostage n'est disponible, réinitialiser les filtres ?
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ParticipeFilter from "@/model/Publipostage/ParticipeFilter";
import ParticipeCardsUser from "@/components/utils/Participe/cardsUser";
import FilterBar from "@/components/filters/FilterBar";
import { FILTER_TYPES } from "@/components/filters/filterTypes";

export default {
  name: "PublipostagesUser",
  components: {
    ParticipeCardsUser,
    FilterBar,
  },
  data() {
    return {
      date: {},
      participeFilter: new ParticipeFilter(),
      filterConfig: [
        {
          fields: ["_searchAgences"],
          type: FILTER_TYPES.AGENCE_TYPE,
          props: {
            multiple: true,
          },
        },
        {
          fields: ["_searchStatus"],
          type: FILTER_TYPES.STATUS_TYPE,
        },
        {
          fields: ["_searchMonth", "_searchYear"],
          type: FILTER_TYPES.MONTH_YEAR_TYPE,
        },
      ],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.users.currentUser;
    },
    participes() {
      return this.$store.state.participes.participes;
    },
  },
  watch: {
    participeFilter: {
      handler() {
        this.search();
      },
      deep: true,
    },
  },
  async mounted() {
    await this.search();
  },
  methods: {
    changeDate(date) {
      this.participeFilter._searchDate = date;
    },
    async search() {
      const { dispatch } = this.$store;

      await dispatch({
        type: "participes/getParticipes",
        participeFilter: this.participeFilter,
      });
    },
    clearFilter() {
      this.participeFilter = new ParticipeFilter();
    },
    callValidate() {
      this.$refs.validate.handleCreateSubmit();
    },
  },
};
</script>

<style lang="scss">
.month-picker-input-container {
  width: 100%;
}

.month-picker__container {
  background-color: white;
}
</style>
