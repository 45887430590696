<template>
  <div class="flex flex-col border border-gray-200 gap-4 rounded-2xl p-5">
    <h2 class="font-medium text-gray-400 mb-4">Montant du CA</h2>
    <counter v-if="isLoaded" :value="value" />
  </div>
</template>

<script>
import Counter from "@/components/reporting/indicators/Counter";
import { reportingService } from "@/services";

export default {
  name: "TurnoverTotal",
  components: {
    Counter,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoaded: false,
      value: null,
    };
  },
  watch: {
    filters() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      const data = await reportingService.getCaTotal(this.filters);
      this.value = Number(data.value);
      this.isLoaded = true;
    },
  },
};
</script>
