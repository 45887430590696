<template>
  <div>
    <div class="h-16 flex items-center">
      <h1 class="text-base lg:text-xl font-semibold mr-auto">
        Gestion des utilisateurs
      </h1>
      <div class="hidden lg:flex mx-auto justify-center items-center">
        <SecondaryNavItem link="/utilisateurs/technitoit">
          Technitoit
        </SecondaryNavItem>
        <SecondaryNavItem link="/utilisateurs/europe-energie">
          Europe Energie
        </SecondaryNavItem>
        <SecondaryNavItem link="/utilisateurs/admin"> Admin </SecondaryNavItem>
      </div>
      <BaseModal
        class="ml-auto mr-4"
        modal-size="lg:max-w-3xl"
        @validate="callValidate"
      >
        <template v-slot:button-content>
          <font-awesome-icon icon="plus-circle" size="2x" />
          <p class="ml-4">Créer un utilisateur</p>
        </template>
        <template v-slot:title>
          <h3 class="text-lg lg:text-xl font-semibold">
            Création d'un utilisateur
          </h3>
        </template>
        <template v-slot:content>
          <UserForm ref="validate" />
        </template>
      </BaseModal>
      <slot name="header-buttons" />
    </div>
    <div class="flex lg:hidden justify-center items-center my-11">
      <SecondaryNavItem link="/utilisateurs/technitoit">
        Technitoit
      </SecondaryNavItem>
      <SecondaryNavItem link="/utilisateurs/europe-energie">
        Europe Energie
      </SecondaryNavItem>
      <SecondaryNavItem link="/utilisateurs/admin"> Admin </SecondaryNavItem>
    </div>
  </div>
</template>

<script>
import SecondaryNavItem from "@/components/utils/menu/SecondaryNavItem";
import BaseModal from "@/components/modals/baseModal";
import UserForm from "@/components/forms/user/UserForm";

export default {
  name: "UtilisateurMenu",
  components: {
    SecondaryNavItem,
    BaseModal,
    UserForm,
  },
  props: {},
  data: () => ({}),
  methods: {
    callValidate() {
      this.$refs.validate.handleCreateSubmit();
    },
  },
};
</script>

<style lang="scss" scoped></style>
