<template>
  <div
    v-click-outside="closeAction"
    class="flex w-full items-center justify-center"
  >
    <div
      v-if="publipostage.status !== 'INACTIF'"
      class="
        hidden
        lg:block
        flex-shrink-0
        p-1
        w-6
        h-6
        border border-gray-200
        rounded-md
        cursor-pointer
        mr-2
      "
      @click="toggleSwitch(publipostage.uuid)"
    >
      <div
        class="w-4 h-4 w-full h-full rounded-sm"
        :class="isSelected.includes(publipostage.uuid) ? 'bg-primary' : ''"
      />
    </div>
    <div
      class="flex w-full flex-wrap h-full"
      :class="{ 'lg:ml-8': publipostage.status === 'INACTIF' }"
    >
      <div
        class="
          relative
          flex
          items-center
          border border-gray-200
          rounded-2xl
          w-full
          h-full
        "
      >
        <div
          class="absolute left-0 top-0 rounded-l-lg h-full flex-shrink-0 w-2"
          :class="getCardsClass(publipostage.marque)"
        />
        <div class="flex flex-wrap p-3 pl-5">
          <div class="flex justify-between items-center w-full">
            <span class="font-bold flex-grow">{{ publipostage.nom }}</span>
            <span
              class="px-4 py-2 rounded-full flex-none text-sm font-medium"
              :style="buildStatusStyle(publipostage.status)"
              >{{ statusFormat(publipostage.status) }}</span
            >
            <div class="flex items-center mx-5">
              <div>
                <span
                  id="user-menu"
                  class="
                    max-w-xs
                    bg-white
                    flex
                    items-center
                    text-sm
                    rounded-t-xl
                    pr-2
                    pl-3
                    flex
                    items-center
                  "
                  :class="isOn ? '' : 'rounded-b-xl'"
                  aria-haspopup="true"
                  @click="isOn = !isOn"
                >
                  <font-awesome-icon
                    class="cursor-pointer text-gray-400"
                    icon="ellipsis-h"
                    size="2x"
                  />
                </span>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-between w-full mt-2">
            <div class="flex">
              <img
                src="@/assets/ico/date.svg"
                alt="icone date"
                class="w-5 mr-2"
              />
              <p class="text-sm">
                {{ dateFormat(publipostage.dateLimite) }}
              </p>
            </div>
            <div class="flex">
              <p class="text-sm">
                {{ nbJoursRestant(publipostage.dateLimite, publipostage) }}
              </p>
            </div>
          </div>
          <div class="flex flex-wrap w-full mt-3">
            <div
              class="
                overflow-hidden
                h-2
                text-xs
                flex
                rounded
                bg-indigo-200
                w-full
              "
            >
              <div
                :style="'width:' + calculatePourcentage() + '%;'"
                class="
                  shadow-none
                  flex flex-col
                  text-center
                  whitespace-nowrap
                  text-white
                  justify-center
                  bg-indigo-500
                "
              />
            </div>
            <p class="text-indigo-500">{{ calculatePourcentage() }}% généré</p>
          </div>
          <div class="flex flex-wrap w-full items-center mt-3 pb-3">
            <publipostage-list-agences
              :publipostage="publipostage"
              :publipostage-filter="publipostageFilter"
            />
          </div>
        </div>
      </div>
      <div class="flex relative w-full -mt-4">
        <div
          v-show="isOn"
          class="
            origin-top-right
            absolute
            right-0
            w-48
            rounded-b-xl
            py-1
            bg-white
            border
            shadow-lg
            w-full
            z-10
          "
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="user-menu"
        >
          <BaseModal
            v-if="publipostage.status === 'TERMINE'"
            ref="sendedPublipostage"
            button-class="block px-4 py-2 text-sm text-primary hover:bg-gray-100 text-left w-full"
            @validate="sendedPublipostage(publipostage.uuid)"
          >
            <template v-slot:button-content>
              <font-awesome-icon class="mr-2" icon="envelope" size="1x" />
              Marquer comme envoyé
            </template>
            <template v-slot:title>
              <h3 class="text-2xl font-semibold">Marquer comme envoyé</h3>
            </template>
            <template v-slot:content>
              <div class="flex flex-wrap items-center justify-center w-full">
                <div class="text-center">
                  <p>
                    Êtes-vous sûr de vouloir marquer ce publipostage comme
                    envoyé ?
                  </p>
                  <p>
                    Attention, les agences pourrons exporter les adresses au
                    format csv.
                  </p>
                </div>
              </div>
            </template>
            <template v-slot:validation-content>
              Marquer comme envoyé
            </template>
          </BaseModal>
          <BaseModal
            v-if="publipostage.status === 'ACTIF'"
            ref="finishPublipostage"
            button-class="block px-4 py-2 text-sm text-primary hover:bg-gray-100 text-left w-full"
            @validate="finishPublipostage(publipostage.uuid)"
          >
            <template v-slot:button-content>
              <font-awesome-icon class="mr-2" icon="check" size="1x" />
              Terminer
            </template>
            <template v-slot:title>
              <h3 class="text-2xl font-semibold">Fin d'un publipostage</h3>
            </template>
            <template v-slot:content>
              <div class="flex flex-wrap items-center justify-center w-full">
                <div class="text-center">
                  <p>Êtes-vous sûr de vouloir terminer ce publipostage ?</p>
                  <p>
                    Attention, les agences ne pourront plus saisir d'adresse.
                  </p>
                </div>
              </div>
            </template>
            <template v-slot:validation-content> Terminer </template>
          </BaseModal>
          <BaseModal
            ref="editPublipostage"
            button-class="block px-4 py-2 text-sm text-primary hover:bg-gray-100 text-left w-full"
            @validate="editPublipostage"
          >
            <template v-slot:button-content>
              <font-awesome-icon class="mr-2" icon="pen" size="1x" />
              Modifier
            </template>
            <template v-slot:title>
              <h3 class="text-2xl font-semibold">
                Modification d'un publipostage
              </h3>
            </template>
            <template v-slot:content>
              <publipostage-form
                ref="edit"
                :publipostage="publipostageModel"
                :publipostage-uuid="publipostage.uuid"
                :publipostage-filter="publipostageFilter"
                @closeEditModal="closeEditModal()"
              />
            </template>
          </BaseModal>
          <BaseModal
            button-class="block px-4 py-2 text-sm text-primary hover:bg-gray-100 text-left w-full"
            modal-size="md:max-w-md"
            @validate="removePublipostage(publipostage.uuid)"
          >
            <template v-slot:button-content>
              <font-awesome-icon class="mr-2" icon="trash" size="1x" />
              Supprimer
            </template>
            <template v-slot:title>
              <div class="flex flex-wrap items-center justify-center w-full">
                <h2 class="text-lg font-bold">Supprimer</h2>
              </div>
            </template>
            <template v-slot:content>
              <div class="flex flex-wrap items-center justify-center w-full">
                <div class="text-center">
                  Êtes-vous sûr de vouloir supprimer ce publipostage ?
                </div>
              </div>
            </template>
            <template v-slot:validation-content> Supprimer </template>
          </BaseModal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import PublipostageListAgences from "@/components/utils/Publipostage/listAgence";
import BaseModal from "@/components/modals/baseModal";
import PublipostageModel from "@/model/Publipostage";
import PublipostageForm from "@/components/forms/publipostage/PublipostageForm";

export default {
  name: "PublipostageCards",
  components: {
    PublipostageForm,
    PublipostageListAgences,
    BaseModal,
  },
  directives: {
    ClickOutside,
  },
  props: {
    isSelected: {
      type: Array,
      required: true,
    },
    publipostage: {
      type: Object,
      required: true,
    },
    publipostageFilter: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isOn: false,
  }),
  computed: {
    publipostageModel() {
      return new PublipostageModel(
        this.publipostage.nom,
        {
          key: this.publipostage.marque.code,
          value: this.publipostage.marque.nom,
        },
        this.publipostage.dateMiseEnLigne,
        this.publipostage.dateLimite
      );
    },
  },
  methods: {
    toggleSwitch(publipostageUuid) {
      if (this.isSelected.includes(publipostageUuid)) {
        this.isSelected.splice(this.isSelected.indexOf(publipostageUuid), 1);
      } else {
        this.isSelected.push(publipostageUuid);
      }
    },
    closeAction() {
      this.isOn = false;
    },
    dateFormat(date) {
      const dateOptions = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const timeOptions = {
        hour: "2-digit",
        minute: "2-digit",
      };

      const objDate = new Date(date);

      return (
        objDate.toLocaleDateString("fr-FR", dateOptions) +
        " à " +
        objDate.toLocaleTimeString("fr-FR", timeOptions)
      );
    },
    nbJoursRestant(date2, publipostage) {
      let objetDate1 = new Date();
      let objetDate2 = new Date(date2);
      if (objetDate1 > objetDate2 || publipostage.status !== "ACTIF") {
        return "";
      }

      let diffTemps = objetDate2.getTime() - objetDate1.getTime();
      return (diffTemps / (1000 * 3600 * 24)).toFixed() + "  jour(s) restants";
    },
    statusFormat(format) {
      switch (format) {
        case "INACTIF":
          return "Inactif";
        case "ACTIF":
          return "Actif";
        case "TERMINE":
          return "Terminé";
        case "ENVOYE":
          return "Envoyé";
        default:
          return format;
      }
    },
    buildStatusStyle(format) {
      switch (format) {
        case "INACTIF":
          return "background: #feeff6; color: #FF3693;";
        case "ACTIF":
          return "background: #EBFBEF; color: #7BD96E;";
        case "TERMINE":
          return "background: #f3efff; color: #9D4AFF;";
        case "ENVOYE":
          return "background: #e1ebff; color: #267EB1;";
        default:
          return format;
      }
    },
    editPublipostage() {
      this.$refs.edit.handleEditSubmit();
    },
    closeEditModal() {
      this.$refs.editPublipostage.closeModal();
    },
    finishPublipostage(publipostageUuid) {
      const { dispatch } = this.$store;

      if (publipostageUuid) {
        dispatch("publipostages/finishPublipostage", {
          publipostageUuid: publipostageUuid,
          publipostageFilter: this.publipostageFilter,
        });
      }
      this.$refs.finishPublipostage.closeModal();
    },
    sendedPublipostage(publipostageUuid) {
      const { dispatch } = this.$store;

      if (publipostageUuid) {
        dispatch("publipostages/sendedPublipostage", {
          publipostageUuid: publipostageUuid,
          publipostageFilter: this.publipostageFilter,
        });
      }
      this.$refs.sendedPublipostage.closeModal();
    },
    removePublipostage(publipostageUuid) {
      const { dispatch } = this.$store;

      if (publipostageUuid) {
        dispatch("publipostages/deletePublipostage", {
          publipostageUuid: publipostageUuid,
          publipostageFilter: this.publipostageFilter,
        });
      }
    },
    getCardsClass(marque) {
      if (marque.code === "technitoit") {
        return "bg-technitoit";
      }

      return "bg-europeenergie";
    },
    calculatePourcentage() {
      const participeOk = this.publipostage.participes.filter(
        (participe) => participe.isDone === true && participe.participe === true
      );
      const participeNotOk = this.publipostage.participes.filter(
        (participe) =>
          participe.isDone === false && participe.participe === true
      );

      if (participeOk.length === 0) {
        return 0;
      }

      if (participeNotOk.length === 0) {
        return 100;
      }

      return (
        (100 * participeOk.length) /
        (participeOk.length + participeNotOk.length)
      ).toFixed();
    },
  },
};
</script>
