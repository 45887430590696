<template>
  <div class="lg:mt-11">
    <div class="border rounded-xl border-gray-200 p-4 h-full my-4">
      <div class="flex flex-wrap">
        <div class="w-full grid grid-cols-6 mt-3 mb-2 text-sm">
          <div class="flex pl-2 text-gray-300 focus-within:text-gray-400">
            <label for="searchNom">
              <font-awesome-icon icon="search" size="1x" class="mr-2" />
            </label>
            <input id="searchNom" v-model="nom" type="text" placeholder="Nom" />
          </div>
          <div class="flex pl-2 text-gray-300 focus-within:text-gray-400">
            <label for="searchPrenom">
              <font-awesome-icon icon="search" size="1x" class="mr-2" />
            </label>
            <input
              id="searchPrenom"
              v-model="prenom"
              type="text"
              placeholder="Prénom"
            />
          </div>
          <div class="flex pl-2 text-gray-300 focus-within:text-gray-400">
            <label for="searchEmail">
              <font-awesome-icon icon="search" size="1x" class="mr-2" />
            </label>
            <input
              id="searchEmail"
              v-model="email"
              type="text"
              placeholder="Email"
            />
          </div>
          <div class="text-gray-300">Rôle</div>
          <div class="col-span-2 text-gray-300">Agence</div>
        </div>
        <BaseModal
          v-for="user in users"
          :key="user.uuid"
          button-size="w-full"
          button-class="text-left user w-full border rounded-xl border-gray-200 grid grid-cols-6 hover:shadow-md cursor-pointer mb-3"
          @validate="callValidate"
        >
          <template v-slot:button-content>
            <div class="nom h-full p-5">
              {{ user.nom }}
            </div>
            <div class="prenom h-full p-5 border-l border-gray-200">
              {{ user.prenom }}
            </div>
            <div class="h-full email p-5 border-l border-gray-200 break-words">
              {{ user.email }}
            </div>
            <div class="border-l border-gray-200 h-full p-5">
              <div
                v-for="role in user.roles"
                v-show="role !== 'ROLE_USER'"
                :key="role"
              >
                {{ displayRole(role) }}
              </div>
            </div>
            <div
              class="
                col-span-2
                flex flex-wrap
                justify-between
                border-l border-gray-200
                h-full
              "
            >
              <div class="w-11/12 px-4 py-2 flex flex-wrap">
                <div
                  v-for="agence in user.agences"
                  :key="agence.uuid"
                  class="mb-1 mr-1"
                >
                  <span
                    class="
                      block
                      text-center
                      rounded-full
                      text-white
                      px-4
                      py-1
                      text-xs
                    "
                    :class="getAgenceColor(agence)"
                    >{{ agence.nom }}</span
                  >
                </div>
              </div>
              <div
                class="
                  bg-primary
                  w-8
                  h-full
                  rounded-r-xl
                  right
                  hidden
                  2xl:block
                "
              />
            </div>
          </template>
          <template v-slot:title>
            <div
              class="
                rounded-full
                h-24
                w-24
                flex
                items-center
                justify-center
                mx-auto
              "
              :class="'bg-' + user.color"
            >
              <h1 class="text-4xl text-white">
                {{ user.initials }}
              </h1>
            </div>
            <BaseModal
              button-class="rounded-lg shadow-lg flex items-center justify-center p-3 absolute top-4 right-0"
              modal-size="md:max-w-md"
              @validate="removeUser(user.uuid)"
            >
              <template v-slot:button-content>
                <img
                  src="@/assets/ico/trash.svg"
                  alt="Logo du publipostage"
                  class="h-6 w-auto"
                />
              </template>
              <template v-slot:title>
                <div class="flex flex-wrap items-center justify-center w-full">
                  <h2 class="text-lg font-bold">Supprimer</h2>
                </div>
              </template>
              <template v-slot:content>
                <div class="flex flex-wrap items-center justify-center w-full">
                  <div class="text-center">
                    Êtes-vous sûr de vouloir supprimer cet utilisateur ?
                  </div>
                </div>
              </template>
              <template v-slot:validation-content> Supprimer </template>
            </BaseModal>
          </template>
          <template v-slot:content>
            <UserForm
              ref="validate"
              :user-uuid="user.uuid"
              :users-roles="user.roles"
              :nom="user.nom"
              :prenom="user.prenom"
              :email="user.email"
              :users-agences="user.agences"
            />
          </template>
        </BaseModal>
        <div
          v-show="users && users.length === 0"
          class="
            flex
            justify-center
            items-center
            w-full
            border-t border-gray-200
            mt-2
          "
        >
          <div
            class="
              p-2
              bg-primary
              items-center
              text-gray-100
              leading-none
              lg:rounded-full
              flex
              lg:inline-flex
              mt-5
              cursor-pointer
            "
            role="alert"
            @click="initFilter"
          >
            <span class="px-2 py-1 font-semibold mr-2 text-left flex-auto"
              >Aucun utilisateur trouvé, réinitialiser vos filtres ?</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseModal from "@/components/modals/baseModal";
import UserForm from "@/components/forms/user/UserForm";
import UserModel from "@/model/UsersFilter";

export default {
  name: "UtilisateursEuropeEnergie",
  components: {
    BaseModal,
    UserForm,
  },
  data() {
    return {
      nom: "",
      prenom: "",
      email: "",
    };
  },
  computed: {
    users() {
      let users = this.$store.state.users.users.europe_energie;

      if (this.nom && this.nom.length) {
        users = users.filter((user) =>
          user.nom.toLowerCase().includes(this.nom.toLowerCase())
        );
      }

      if (this.prenom && this.prenom.length) {
        users = users.filter((user) =>
          user.prenom.toLowerCase().includes(this.prenom.toLowerCase())
        );
      }

      if (this.email && this.email.length) {
        users = users.filter((user) =>
          user.email.toLowerCase().includes(this.email.toLowerCase())
        );
      }

      return users;
    },
  },
  mounted() {
    const { dispatch } = this.$store;
    const usersFilter = new UserModel();

    dispatch({
      type: "users/getUsers",
      usersFilter: usersFilter,
      page: "europe_energie",
    });
  },
  methods: {
    callValidate() {
      this.$refs.validate[0].handleEditSubmit();
    },
    initFilter() {
      this.nom = "";
      this.prenom = "";
      this.email = "";
    },
    removeUser(userUuid) {
      const { dispatch } = this.$store;

      if (userUuid) {
        dispatch("users/deleteUser", { userUuid });
      }
    },
    displayRole: function (role) {
      switch (role) {
        case "ROLE_SECRETAIRE":
          return "Secrétaire";
        case "ROLE_DA":
          return "Directeur d'agence";
        case "ROLE_DZ":
          return "Directeur de zone";
        default:
          return role;
      }
    },
    getAgenceColor(agence) {
      if (agence.marque.code === "technitoit") {
        return "bg-technitoit";
      } else if (agence.marque.code === "europe_energie") {
        return "bg-europeenergie";
      }

      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
.user {
  position: relative;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-in-out;
}

.user::after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 0.75rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease-in-out;
}

.user:hover {
  transform: scale(1, 1);
}

/* Fade in the pseudo-element with the bigger shadow */
.user:hover::after {
  opacity: 1;
}

.trash {
  @apply text-red-600;
}
</style>
