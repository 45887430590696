<template>
  <div
    class="
      flex flex-col
      w-full
      h-full
      p-2
      border border-gray-200
      rounded-xl
      group
    "
  >
    <div class="w-full flex justify-between items-center p-4">
      <div>
        <img
          v-if="brand === 'technitoit'"
          src="@/assets/ico/agence-technitoit.svg"
          alt="Icone Technitoit"
          class="h-16 w-auto"
        />
        <img
          v-else
          src="@/assets/ico/agence-europe-energie.svg"
          alt="Icone Europe Energie"
          class="h-16 w-auto"
        />
      </div>
      <div>
        <h3 class="text-center font-semi-bold">
          {{ agence.nom }}
        </h3>
      </div>
      <div class="border border-gray-200 rounded-xl shadow-lg">
        <img
          v-if="brand === 'technitoit'"
          src="@/assets/ico/publipostage-technitoit.svg"
          alt="Icone publipostage technitoit"
          class="h-12 w-auto"
        />
        <img
          v-else
          src="@/assets/ico/publipostage-europe-energie.svg"
          alt="Icone publipostage technitoit"
          class="h-12 w-auto"
        />
      </div>
    </div>
    <div class="w-full flex justify-center items-center p-4">
      <div class="w-full flex flex-wrap justify-center">
        <div class="w-full text-gray-400 text-center">
          <font-awesome-icon icon="user" size="lg" />
        </div>
        <h3 class="w-full font-semi-bold text-center mt-2">
          {{ agence.responsableNom }}
        </h3>
      </div>
      <div class="border-r border-gray-200 h-12 mx-10" />
      <div class="w-full flex flex-wrap justify-center">
        <div class="w-full text-gray-400 text-center">
          <font-awesome-icon icon="phone" size="lg" />
        </div>
        <h3 class="w-full font-semi-bold text-center mt-2">
          {{ agence.responsableTelephone }}
        </h3>
      </div>
    </div>
    <div
      class="
        w-full
        flex flex-wrap
        justify-center
        items-center
        w-full
        border-t border-gray-200
        p-4
      "
    >
      <div class="text-gray-400">
        <font-awesome-icon icon="map-marker-alt" size="lg" />
      </div>
      <div class="text-sm text-gray-800 ml-3 text-center">
        <p>{{ agence.adresse }}</p>
        <p>{{ agence.codePostal }} {{ agence.ville }}</p>
      </div>
    </div>
    <div
      class="mt-auto w-full flex h-2 rounded-b-lg"
      :class="bottomLineClasses"
    />
  </div>
</template>

<script>
export default {
  name: "AgenceCard",
  props: {
    agence: {
      type: Object,
      required: true,
    },
    brand: {
      type: String,
      required: true,
    },
    noHover: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cardClasses() {
      if (this.noHover) {
        return "";
      }

      return "hover:shadow-lg";
    },

    bottomLineClasses() {
      if (this.noHover) {
        return "";
      }
      if (this.brand === "technitoit") {
        return "group-hover:bg-technitoit group-focus:bg-technitoit";
      }

      return "group-hover:bg-europeenergie group-focus:bg-europeenergie";
    },
  },
};
</script>

<style scoped></style>
