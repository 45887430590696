export default class UserModel {
  _searchNom = null;
  _searchPrenom = null;
  _searchEmail = null;
  _searchRoles = null;
  _searchMarques = [];

  constructor(
    nom = null,
    prenom = null,
    email = null,
    role = [],
    marques = []
  ) {
    this._searchNom = nom;
    this._searchPrenom = prenom;
    this._searchEmail = email;
    this._searchRoles = role;
    this._searchMarques = marques;
  }

  getSearchNom() {
    return this._searchNom;
  }

  getSearchPrenom() {
    return this._searchPrenom;
  }

  getSearchEmail() {
    return this._searchEmail;
  }

  getSearchRoles() {
    return this._searchRoles;
  }

  getSearchMarques() {
    return this._searchMarques;
  }

  addSearchMarques(marqueUuid) {
    return this._searchMarques.push(marqueUuid);
  }

  sanitarizeQueryParam() {
    let str = [];

    if (null !== this.getSearchNom() && "" !== this.getSearchNom()) {
      str.push("searchNom" + "=" + encodeURIComponent(this.getSearchNom()));
    }

    if (null !== this.getSearchPrenom() && "" !== this.getSearchPrenom()) {
      str.push(
        "searchPrenom" + "=" + encodeURIComponent(this.getSearchPrenom())
      );
    }

    if (null !== this.getSearchEmail() && "" !== this.getSearchEmail()) {
      str.push("searchEmail" + "=" + encodeURIComponent(this.getSearchEmail()));
    }

    if (this.getSearchRoles().length > 0) {
      for (const role in this.getSearchRoles()) {
        str.push(
          "searchRoles[]" +
            "=" +
            encodeURIComponent(this.getSearchRoles()[role])
        );
      }
    }

    if (this.getSearchMarques().length > 0) {
      for (const marque in this.getSearchMarques()) {
        str.push(
          "searchMarques[]" +
            "=" +
            encodeURIComponent(this.getSearchMarques()[marque])
        );
      }
    }

    return str.join("&");
  }
}
