<template>
  <div>
    <UtilisateurMenu>
      <template v-slot:header-buttons>
        <slot name="header-buttons" />
      </template>
    </UtilisateurMenu>
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import UtilisateurMenu from "@/components/utils/menu/UtilisateurMenu";

export default {
  name: "Utilisateurs",
  components: {
    UtilisateurMenu,
  },
};
</script>
